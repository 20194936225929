<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-col cols="12" sm="12" md="12" lg="12" v-if="customer && customer.name">
          <v-list-item>
            <v-list-item-avatar color="primary" class="headline font-weight-light white--text">{{
          customer.name.charAt(0) }}</v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="customer.name"></v-list-item-title>
              <v-list-item-subtitle v-text="customer.companynumber"></v-list-item-subtitle>
              <v-list-item-subtitle>{{ customer.street }} {{ customer.houseno }},
                {{ customer.zip }} {{ customer.city }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-tabs v-model="tab" background-color="grey lighten-2">
          <v-tab key="info">Informationen</v-tab>
          <v-tab key="standorte">Standorte ({{ standorte.length }})</v-tab>
          <v-tab key="anlagen">Anlagen ({{ anlagen.length }})</v-tab>
          <v-tab key="blocks">Wartungsblöcke ({{ blocks.length }})</v-tab>
          <v-tab key="assignment">Aufträge ({{ assignments.length }})</v-tab>
          <v-tab key="docs">Dokumente ({{ documents.length }})</v-tab>
          <v-tab key="prices">Preisliste ({{ pricelist.length }})</v-tab>
          <v-tab key="materials">Materialliste ({{ materials.length }})</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="full-width">
          <v-tab-item key="info">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <div class="card-title">Allgemeine Informationen</div>
                <v-container grid-list-md>
                  <v-form ref="form">
                    <v-layout wrap>
                      <v-flex xs12 sm12 md8>
                        <v-text-field v-model="customer.name" label="Name*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md4>
                        <v-text-field v-model="customer.customercode" label="Kundenkürzel*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="customer.companynumber" label="Kundennummer"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="customer.contactperson" label="Konataktperson"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field type="email" v-model="customer.email" label="E-Mail"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field type="phone" v-model="customer.phone" label="Telefon"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm8 md8>
                        <v-text-field v-model="customer.street" label="Straße*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field v-model="customer.houseno" label="Hausnummer*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm5 md3>
                        <v-text-field type="number" v-model="customer.zip" max="99999" label="PLZ*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm7 md9>
                        <v-text-field v-model="customer.city" label="Stadt*" required
                          :rules="[rules.required]"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12>
                        <v-textarea v-model="customer.comment" outlined label="Kommentar"></v-textarea>
                      </v-flex>
                      <v-flex xs12 sm7 md9>
                        <v-checkbox v-model="customer.wartungsvertrag" label="Wargungsvertrag"></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-switch v-model="customer.active" label="Aktiv"></v-switch>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="updateData()">Informationen aktualisieren</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item key="standorte">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersstandorte" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="standorte">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Standorte ({{ standorte.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="showNewStandort = true" fab small color="success">
                            <i class="material-icons">add</i>
                          </v-btn>
                        </template>
                        <span>Neuen Standort anlegen</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.address="{ item }">{{ item.street }} {{ item.houseno }}, {{ item.zip }}
                    {{ item.city }}</template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="changeStandort(item)" color="primary" small fab>
                            <i class="material-icons">settings</i>
                          </v-btn>
                        </template>
                        <span>Standort bearbeiten</span>
                      </v-tooltip>

                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.stop="
          confirmDeleteItem(item.id, item.name, 'standort')
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Standort löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="anlagen">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
                  <v-text-field v-model="search1" append-icon="search" label="Suche"></v-text-field>
                </v-col>
                <v-data-table :headers="headers" :search="search1" height="inherit" hide-default-footer
                  :items-per-page="-1" :items="anlagen">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Anlagen ({{ anlagen.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn @click="$router.push('/anlage/new/' + id)" fab color="success">
                        <i class="material-icons">add</i>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          $router.push(
            '/anlage/edit/' + item.id + '/-1/' + id
          )
          " color="primary" small fab>
                            <i class="material-icons">settings</i>
                          </v-btn>
                        </template>
                        <span>Anlage bearbeiten</span>
                      </v-tooltip>

                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="$router.push('/anlagehistory/' + item.id)" color="primary" small fab>
                            <i class="material-icons">alarm_on</i>
                          </v-btn>
                        </template>
                        <span>Wartungshistorie anzeigen</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="duplicateItem(item)" color="primary" small fab>
                            <i class="material-icons">filter_1</i>
                          </v-btn>
                        </template>
                        <span>Anlagen duplizieren</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          confirmDeleteItem(item.id, item.name, 'anlage')
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Anlage löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.timestamps="{ item }">
                    <small>Erstellt am: {{ $formatDate(item.created_at) }}</small>
                    <br />
                    <small>Aktualsiert am: {{ $formatDate(item.updated_at) }}</small>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="blocks">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersblocks" hide-default-footer :items-per-page="-1" :items="blocks">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Wartungsblöcke ({{ blocks.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn @click="$router.push('/block/new/' + id)" fab color="success">
                        <i class="material-icons">add</i>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.nextservice="{ item }">
                    <div v-html="getClass(item.nextservice)"></div>
                  </template>
                  <template v-slot:item.lastservice="{ item }">
                    <div>{{ $formatDate(item.lastservice) }}</div>
                  </template>
                  <template v-slot:item.refdate="{ item }">
                    <div>{{ $formatDate(item.refdate) }}</div>
                  </template>
                  <template v-slot:item.timestamps="{ item }">
                    <small>Erstellt am: {{ $formatDate(item.created_at) }}</small>
                    <br />
                    <small>Aktualsiert am: {{ $formatDate(item.updated_at) }}</small>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="$router.push('/block/edit/' + item.id)" color="primary" small fab>
                            <i class="material-icons">settings</i>
                          </v-btn>
                        </template>
                        <span>Wartungblock bearbeiten</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="$router.push('/blockhistory/' + item.id)" color="primary" small fab>
                            <i class="material-icons">alarm_on</i>
                          </v-btn>
                        </template>
                        <span>Wartungsblock Historie anzeigen</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          confirmDeleteItem(item.id, item.name, 'block')
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Wartungsblock löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="assignment">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersassignment" :items="assignments" class="row-pointer"
                  @click:row="handleClick" sort-by="date">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Aufträge ({{ assignments.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!--  <v-btn @click="$router.push('/block/new/'+ id)" fab color="success">
                          <i class="material-icons">add</i>
                      </v-btn>-->
                    </v-toolbar>
                  </template>
                  <template v-slot:item.updated_at="{ item }">{{
          $formatDateTime(item.updated_at)
        }}</template>
                  <template v-slot:item.status="{ item }">
                    <span v-if="item.status == '0'" class="orange-highlight">Erstellt</span>
                    <span v-if="item.status == '1'" class="orange-highlight">In Bearbeitung</span>
                    <span v-if="item.status == '2'" class="darkred-highlight">Übermittelt</span>
                    <span v-if="item.status == '3'" class="green-highlight">Abgeschlossen</span>
                  </template>
                  <template v-slot:item.standortname="{ item }">
                    <span v-if="item.standortname">{{
          item.standortname
        }}</span>
                    <span v-if="!item.standortname && item.info">{{
          JSON.parse(item.info).address
        }}</span>
                  </template>
                  <template v-slot:item.date="{ item }">{{
          $formatDate(item.date)
        }}</template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <!--  <v-tooltip
                        left
                        v-if="role !== 'worker' || item.created_by === userid"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="editAssignment(item)"
                            fab
                            small
                            color="primary"
                          >
                            <i class="material-icons">settings</i>
                          </v-btn>
                        </template>
        <span>Auftrag bearbeiten</span>
        </v-tooltip> -->
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="$router.push('/assignment/' + item.id)" fab small color="primary">
                            <i class="material-icons">info</i>
                          </v-btn>
                        </template>
                        <span>Auftragdetails ansehen</span>
                      </v-tooltip>
                      <v-tooltip left v-if="role !== 'worker'">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.stop="
          confirmDeleteItem(
            item.id,
            item.assignmentnumber,
            'assignment'
          )
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Auftrag löschen</span>
                      </v-tooltip>
                      <!-- <v-tooltip left v-if="role !== 'worker'">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :disabled="item.status === '3'"
                            @click="closeAssignment(item.id)"
                            color="success"
                            small
                            fab
                          >
                            <i class="material-icons">check</i>
                          </v-btn>
                        </template>
                        <span>Auftrag abschließen</span>
                      </v-tooltip> -->
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="docs">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersdocuments" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="documents">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Dokumente ({{ documents.length }})</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created_at="{ item }">{{
          $formatDateTime(item.created_at)
        }}</template>
                  <template v-slot:item.filename="{ item }">
                    <div class="link" @click="$openDoc(item)">
                      {{ item.filename }}
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          confirmDeleteItem(item.id, item.filename, 'doc');
        deletePath = item.path;
        " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Dokument löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <hr class="my-5" />
                    <v-row>
                      <v-col cols="12" sm="9" md="9" lg="9">
                        <v-file-input v-model="docs" color="primary accent-4" counter label="Dokumente" multiple
                          placeholder="Hier klicken um Dokumente hochzuladen" prepend-icon="mdi-paperclip" outlined
                          :show-size="1000"></v-file-input>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3">
                        <v-btn @click="uploadDocs()" color="primary" :disabled="docs.length === 0 || !online">Dokumente
                          Hochladen</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="prices">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersprices" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="pricelist">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Preisliste ({{ pricelist.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-btn fab small color="success" @click="addNewPrice()">
                        <v-icon>add</v-icon>
                      </v-btn>
                      <!--  <input type="file" @change="onFileChange" /> -->
                    </v-toolbar>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <v-chip color="secondary" outlined>{{
          $formatCurrency(item.price)
        }}</v-chip>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          confirmDeleteItem(item.id, item.name, 'price')
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Eintrag löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" :disabled="disablePriceBtn" @click="updatePrices()">Preisliste
                  aktualisieren</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item key="materials">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table :headers="headersmaterials" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="materials">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Materialliste ({{ materials.length }})</v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-btn fab small color="success" @click="addNewMaterial()">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <v-chip color="secondary" outlined>{{
          $formatCurrency(item.price)
        }}</v-chip>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
          confirmDeleteItem(item.id, item.name, 'material')
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Eintrag löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" :disabled="disableMaterialBtn" @click="updateMaterials()">Materialliste
                  aktualisieren</v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Löschen</v-card-title>
          <v-card-text v-if="deleteMode == 'anlage'">
            Sind sie sicher, dass Sie die Anlagen
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'assignment'">
            Sind sie sicher, dass Sie den Auftrag
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'block'">
            Sind sie sicher, dass Sie den Wartungsblock
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'standort'">
            Sind sie sicher, dass Sie den Standort
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'doc'">
            Sind sie sicher, dass Sie das Dokument
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'price'">
            Sind sie sicher, dass Sie den Preis-Eintrag
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'material'">
            Sind sie sicher, dass Sie den Material-Eintrag
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="showNewStandort" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span v-if="editStandort" class="headline">Standort bearbeiten</span>
            <span v-else class="headline">Neuen Standort erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="newStandort.name" label="Standortname*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field v-model="newStandort.contactperson" label="Konataktperson"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field type="email" v-model="newStandort.email" label="E-Mail"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field type="phone" v-model="newStandort.phone" label="Telefon"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-text-field v-model="newStandort.street" label="Straße*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field v-model="newStandort.houseno" label="Hausnummer*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md3>
                    <v-text-field type="number" v-model="newStandort.zip" max="99999" label="PLZ*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm7 md9>
                    <v-text-field v-model="newStandort.city" label="Stadt*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewStandort()">Speichern</v-btn>
            <v-btn color="error" text @click="showNewStandort = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="priceDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Preis-Eintrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newPrice.name" label="Bezeichnung*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newPrice.productid" label="Produkt ID"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newPrice.category" label="Kategorie"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="number" v-model="newPrice.price" label="Preis in €*"
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewPrice()">Speichern</v-btn>
            <v-btn color="error" text @click="priceDialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="materialDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Material-Eintrag erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newMaterial.name" label="Name*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newMaterial.materialid" label="Material ID"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="newMaterial.description" label="Beschreibung"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="number" v-model="newMaterial.price" label="Preis in €*" required
                      :rules="[rules.required]"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="createNewMatrial()">Speichern</v-btn>
            <v-btn color="error" text @click="materialDialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      confirm: false,
      showNewStandort: false,
      editStandort: false,
      priceDialog: false,
      materialDialog: false,
      disablePriceBtn: true,
      disableMaterialBtn: true,
      deleteName: "",
      search1:"",
      deleteId: "",
      deleteMode: "",
      deletePath: "",
      anlagen: [],
      blocks: [],
      materials: [],
      standorte: [],
      documents: [],
      pricelist: [],
      docs: [],
      assignments: [],
      customer: {},
      tab: "",
      categories: [
        {
          label: "Büro",
          value: "Büro",
        },
        {
          label: "Montage",
          value: "Montage",
        },
        {
          label: "Service",
          value: "Service",
        },
        {
          label: "Wartung",
          value: "Wartung",
        },
        {
          label: "Anfahrt",
          value: "Anfahrt",
        },
      ],
      newStandort: {
        name: "",
        contactperson: "",
        email: "",
        phone: "",
        street: "",
        houseno: "",
        zip: "",
        city: "",
      },
      newPrice: {
        name: "",
        productid: "",
        price: "",
        category: "",
      },
      newMaterial: {
        name: "",
        price: "",
        description: "",
        materialid: "",
      },
      headersstandorte: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Standortbezeichnung",
          align: "left",
          value: "name",
        },
        {
          text: "Kontaktperson",
          sortable: false,
          value: "contactperson",
        },
        {
          text: "Anschrift",
          sortable: false,
          value: "address",
        },
        {
          text: "E-Mail",
          sortable: false,
          value: "email",
        },
        {
          text: "Telefon",
          sortable: false,
          value: "phone",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude",
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standortname",
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ",
        },
        {
          text: "Seriennummer",
          align: "left",
          sortable: false,
          value: "seriennummer",
        },
        {
          text: "Baujahr",
          align: "left",
          sortable: false,
          value: "baujahr",
        },
        {
          text: "Bemerkung",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Zeitstempel",
          align: "left",
          sortable: false,
          value: "timestamps",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersassignment: [
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "date",
        },
        {
          text: "Auftragsnummer",
          align: "left",
          sortable: true,
          value: "assignmentnumber",
        },
        {
          text: "Mitarbeiternamen",
          align: "left",
          sortable: true,
          value: "usernames",
        },
        {
          text: "Projektleiter",
          align: "left",
          sortable: true,
          value: "plnames",
        },
        {
          text: "Kommentar",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Erstellt",
          align: "left",
          sortable: false,
          value: "creator",
        },
        {
          text: "Zuletzt geändert am",
          align: "left",
          value: "updated_at",
        },
        {
          text: "Status",
          align: "left",
          value: "status",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersblocks: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Letzte Wartung",
          align: "left",
          sortable: false,
          value: "lastservice",
        },
        {
          text: "Nächste Wartung",
          align: "left",
          sortable: false,
          value: "nextservice",
        },
        {
          text: "Referenz-Datum",
          align: "left",
          sortable: false,
          value: "refdate",
        },
        {
          text: "Preis",
          align: "left",
          sortable: false,
          value: "price",
        },
        {
          text: "Zeitstempel",
          align: "left",
          sortable: false,
          value: "timestamps",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersdocuments: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "filename",
        },
        {
          text: "Hochgeladen von",
          align: "left",
          value: "creator",
        },
        {
          text: "Hochgeladen am",
          align: "left",
          value: "created_at",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersprices: [
        {
          text: "Bezeichnung",
          align: "left",
          value: "name",
        },
        {
          text: "Produkt ID",
          align: "left",
          value: "productid",
        },
        {
          text: "Kategorie",
          align: "left",
          value: "category",
        },
        {
          text: "Preis in €",
          align: "left",
          value: "price",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersmaterials: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Material ID",
          align: "left",
          value: "materialid",
        },
        {
          text: "Beschreibung",
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: "Preis in €",
          align: "left",
          value: "price",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted: function () {
    this.getCustomer();
    this.tab = "info";
    /*
    this.customer = {
         "id":"125",
         "name":"Stark Industries",
         "contactperson":"John Stark",
         "grobauflistung":"",
         "wartungsvertrag":"0",
         "email":"",
         "street":"Musterstrasse",
         "houseno":"4",
         "zip":"12345",
         "city":"Gotham",
         "invoicestreet":"",
         "invoicehouseno":"",
         "invoicezip":"0",
         "invoicecity":"",
         "companyid":"2",
         "companynumber":"3456",
         "phone":"0123 - 456789",
         "created_at":"2021-06-10 11:30:37",
         "created_by":"58",
         "updated_at":"2021-06-10 11:30:37",
         "updated_by":"58"
      };

      this.anlagen = [{id:"23"},{id:"235"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"}]
           this.documents = [{id:"2343",created_at:"2021-06-10 11:30:37",creator:"Bruce", filename:"Anlage 1"},{id:"23",created_at:"2021-06-10 11:30:37",creator:"Bruce", filename:"Anlage 1"},{id:"235",created_at:"2021-06-10 11:30:37",creator:"Bruce", filename:"Anlage 1"},{id:"2343",created_at:"2021-06-10 11:30:37",creator:"Bruce", filename:"Anlage 1"}];
                this.standorte = [{
  "city": "Gotham City",
  "contactperson": "John Stark",
  "customerid": "125",
  "email": "info@test.de",
  "houseno": "4",
  "id": "128",
  "name": "Hauptsitz",
  "phone": "01234566431",
  "street": "Musterstrasse",
  "zip": "12344",
   },
   {
  "city": "Metropolis",
  "contactperson": "Bruce Wayne",
  "customerid": "125",
  "email": "info@test.de",
  "houseno": "12",
  "id": "1281",
  "name": "Metro Standort",
  "phone": "01234566431",
  "street": "Musterstrasse",
  "zip": "34324",
   }
   
   ];
                     this.assignments = [{id:"23"},{id:"235"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"},{id:"235"},{id:"2343"}];
                     this.blocks = [{id:"23"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"}];
                     this.pricelist = [{id:"23"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"}];
                     this.materials = [{id:"23"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"},{id:"23"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"},{id:"23"},{id:"2343"},{id:"23"},{id:"235"},{id:"2343"}];
          */
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push("/assignment/" + item.id);
    },
    getClass(value) {
      if (!value || value === "0000-00-00") {
        return "";
      }
      const date = new Date(value);
      const now = new Date();
      const diff = date - now;
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (diffDays < 30) {
        return (
          '<span class="darkred-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      if (diffDays < 60) {
        return (
          '<span class="orange-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      return (
        '<span class="green-highlight">' +
        this.$moment(value).format("DD.MM.YYYY") +
        "</span>"
      );
    },
    changeStandort(item) {
      this.newStandort = JSON.parse(JSON.stringify(item));
      this.editStandort = true;
      this.showNewStandort = true;
    },
    getCustomer() {
      var self = this;
      if (this.online) {
        var self = this;
        let formData = new FormData();
        formData.append("id", this.id);
        /*  this.$http
          .get("customer/customer/" + this.id) */
        this.$http({
          method: "post",
          url: "getCustomerInfo.php",
          data: formData,
        })
          .then((response) => {
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (
              response.data &&
              response.data.success === false &&
              response.data.notAllowed
            ) {
              this.$router.push("/");
            }
            if (response.status === 200 && response.data) {
              if (response.data.customer) {
                const cust = response.data.customer;
                cust.active = cust.active == "1";
                self.customer = cust;
                if (
                  response.data.customer.pricelist &&
                  response.data.customer.pricelist !== ""
                ) {
                  self.pricelist = JSON.parse(response.data.customer.pricelist);
                }
                if (
                  response.data.customer.materiallist &&
                  response.data.customer.materiallist !== ""
                ) {
                  self.materials = JSON.parse(
                    response.data.customer.materiallist
                  );
                }
                if (
                  response.data.customer.productcategories &&
                  response.data.customer.productcategories !== ""
                ) {
                  //   self.categories = JSON.parse(
                  //   response.data.customer.productcategories
                  //    );
                }
              }
              if (response.data.anlagen) {
                self.anlagen = response.data.anlagen;
              }
              if (response.data.blocks) {
                self.blocks = response.data.blocks;
              }
              if (response.data.standorte) {
                self.standorte = response.data.standorte;
              }
              if (response.data.assignments) {
                self.assignments = response.data.assignments;
              }
              if (response.data.documents) {
                self.documents = response.data.documents;
              }
            }
          })
          .catch((error) => {
            this.$router.push("/");
          });
      } else {
        const data = this.$store.getters.offlinedata;
        const id = this.id;
        if (data && data.customers) {
          const customer = data.customers.find((c) => {
            return c.id === id;
          });
          if (customer) {
            self.customer = customer;
          }
        }
        if (data && data.anlagen) {
          const anlagen = data.anlagen.filter((c) => {
            return c.customerid === id;
          });
          if (anlagen && anlagen.length) {
            self.anlagen = anlagen;
          }
        }
      }
    },
    duplicateItem(item) {
      let formData = new FormData();
      formData.append("name", item.name);
      formData.append("standort", item.standort);
      formData.append("typ", item.typ);
      formData.append("gebaeude", item.gebaeude);
      formData.append("baujahr", item.baujahr);
      formData.append("seriennummer", item.seriennummer);
      formData.append("listitems", item.items);
      formData.append("mode", "new");
      formData.append("customerid", item.customerid);
      formData.append("comment", "");
      this.$http({
        method: "post",
        url: "editAnlage.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data.success ||
            response.data.indexOf('"success":true') > -1
          ) {
            const msg = "Die Anlage wurde erfolgreich kopiert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.id = response.data.id;
            this.anlagen.push(newItem);
          } else {
            const msg =
              "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    createNewStandort() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let formData = new FormData();
      formData.append("json", JSON.stringify(this.newStandort));
      if (this.editStandort) {
        formData.append("mode", "edit");
      } else {
        formData.append("mode", "new");
        formData.append("customerid", this.id);
      }
      let self = this;
      this.$http({
        method: "post",
        url: "editStandort.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            let color, msg;
            if (this.editStandort) {
              //TODO
              const standort = JSON.parse(JSON.stringify(this.newStandort));
              let index = -1;
              let standorte = JSON.parse(JSON.stringify(this.standorte));
              standorte.forEach((element, i) => {
                if (element.id === standort.id) {
                  index = i;
                }
              });
              if (index > -1) {
                standorte[index] = standort;
                msg = "Der Standort wurde erfolgreich aktualisiert.";
                color = "success";
              }
              this.standorte = standorte;
            } else {
              let standort = JSON.parse(JSON.stringify(this.newStandort));
              standort.id = response.data.id;
              this.standorte.push(standort);
              msg = "Der Standort wurde erfolgreich erstellt.";
              color = "success";
            }
            Object.keys(this.newStandort).forEach((prop) => {
              this.newStandort[prop] = false;
            });
            this.showNewStandort = false;
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Die Standort konnte nicht erstellt werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    updateData() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let formData = new FormData();
      let customer = JSON.parse(JSON.stringify(this.customer));
      customer.active = customer.active || customer.active === 1 ? 1 : 0;
      customer.wartungsvertrag = customer.wartungsvertrag ? 1 : 0;
      formData.append("json", JSON.stringify(this.customer));
      formData.append("mode", "edit");
      /*  this.$http
        .put("customer/updatecustomer/" + this.id, customer) */
      this.$http({
        method: "post",
        url: "editCustomer.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.status === 200) {
            const msg = "Die Änderungen wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    createNewPrice() {
      if (!this.$refs.form2.validate()) {
        return;
      }
      const newPrice = JSON.parse(JSON.stringify(this.newPrice));
      newPrice.id = this.id + new Date().getTime();
      this.pricelist.push(newPrice);
      this.newPrice = {
        productid: "",
        name: "",
        price: "",
        category: "",
      };
      this.disablePriceBtn = false;
      this.priceDialog = false;
    },

    createNewMatrial() {
      if (!this.$refs.form2.validate()) {
        return;
      }
      const newMaterial = JSON.parse(JSON.stringify(this.newMaterial));
      this.materials.push(newMaterial);
      this.newMaterial = {
        name: "",
        price: "",
        description: "",
        materialid: "",
      };
      this.disableMaterialBtn = false;
      this.materialDialog = false;
    },
    uploadDocs() {
      this.$uploadDocs(this.docs, this.id, "customer", "customer")
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.files) {
            const customerid = this.id;
            const username = this.$store.getters.user.name;
            const userid = this.$store.getters.user.id;
            response.data.files.forEach((file) => {
              this.documents.push({
                id: file.id,
                path: file.path,
                filename: file.filename,
                created_at: this.$formatDateTimeISO(new Date()),
                created_by: userid,
                creator: username,
                customerid: customerid,
              });
            });
            this.docs = [];
            const msg = "Dokumente wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Dokumente konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
    addNewPrice() {
      this.priceDialog = true;
    },
    addNewMaterial() {
      this.materialDialog = true;
    },
    confirmDeleteItem(id, name, mode) {
      this.deleteName = name;
      this.deleteId = id;
      this.deleteMode = mode;
      this.confirm = true;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createInput(files[0]);
    },
    createInput(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        const result = reader.result;
        const lines = result.split(/\r\n|\n/);
        lines.forEach((line, i) => {
          if (i > 0) {
            const parts = line.split(";");
            this.pricelist.push({
              name: parts[0],
              productid: parts[1],
              category: parts[2],
              price: parts[3],
              id: this.id + i,
            });
          }
        });
      };
      reader.readAsText(file);
      console.log(this.fileinput);
    },
    updateMaterials() {
      let formData = new FormData();
      formData.append("materiallist", JSON.stringify(this.materials));
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "editCustomerMateriallist.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Materialliste wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.disableMaterialBtn = true;
          } else {
            const msg =
              "Die Materialliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Die Materialliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    updatePrices() {
      let formData = new FormData();
      formData.append("pricelist", JSON.stringify(this.pricelist));
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "editCustomerPricelist.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Preisliste wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.disablePriceBtn = true;
          } else {
            const msg =
              "Die Preisliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Die Preisliste konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    deleteItem() {
      if (this.deleteMode === "price") {
        this.pricelist.forEach((b, i) => {
          if (b.id === this.deleteId) {
            this.pricelist.splice(i, 1);
            this.deleteName = "";
            this.deleteId = "";
            this.confirm = false;
            this.disablePriceBtn = false;
          }
        });
        return;
      }
      if (this.deleteMode === "material") {
        this.materials.forEach((b, i) => {
          if (b.id === this.deleteId) {
            this.materials.splice(i, 1);
            this.deleteName = "";
            this.deleteId = "";
            this.confirm = false;
            this.disableMaterialBtn = false;
          }
        });
        return;
      }
      let formData = new FormData();
      formData.append("id", this.deleteId);
      let url;
      if (this.deleteMode === "anlage") {
        url = "deleteAnlage.php";
      }
      if (this.deleteMode === "assignment") {
        url = "deleteAssignment.php";
      }
      if (this.deleteMode === "block") {
        url = "deleteBlock.php";
      }
      if (this.deleteMode === "standort") {
        url = "deleteStandort.php";
      }
      if (this.deleteMode === "doc") {
        url = "deleteDocument.php";
        formData.append("path", this.deletePath);
      }
      if (!url) {
        return;
      }
      this.$http({
        method: "post",
        url: url,
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.data && response.data.success) {
            if (this.deleteMode === "assignment") {
              this.assignments.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.assignments.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
            }
            if (this.deleteMode === "anlage") {
              this.anlagen.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.anlagen.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
            }
            if (this.deleteMode === "standort") {
              this.standorte.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.standorte.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
            }
            if (this.deleteMode === "doc") {
              this.documents.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.documents.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.deletePath = "";
                  this.confirm = false;
                }
              });
            }
            if (this.deleteMode === "block") {
              this.blocks.forEach((b, i) => {
                if (b.id === this.deleteId) {
                  this.blocks.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
            }
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
  },
};
</script>
